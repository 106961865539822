import { ref } from "@vue/reactivity";
import { computed } from "vue-demi";
export const useWatchScan = () => {
    // true 为聚焦中  false 为 失焦中
    const openWatch=(cb)=>{
            // 监听PDA 扫描物理按键
            document.addEventListener('keydown', event => {
                if(event.keyCode==0){
                   cb && cb(document.activeElement.nodeName==='INPUT')   
                }
            })
    }
    return{
        openWatch
    }
}
