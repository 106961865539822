<template>
  <div class="tw-flex tw-flex-col">
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"' @click='active = 1'>扫码界面</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"' @click='getProductInfo();active = 2'>出库商品</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 3 ? "s_bf054 tw-text-white":"s_bf"' @click='search.out_warehousing_sn=form.out_warehousing_sn;resetLoading();active = 3'>物流码明细</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-form @submit="Shipment">
        <van-field v-model="form.out_warehousing_to_name" is-link readonly name="out_warehousing_to_name" label="收货人" placeholder="请选择收货人"  required :rules="[{ required: true, message: '请选择收货人' }]" @click="()=>{if(isDisabled){return} storeShow = true}" />
        <van-field name="use_product_batch" label="物流配送">
          <template #input>
            <van-switch @change="onSelect" :disabled='isDisabled' active-color='#5B9DFF' v-model="showType" size="20" />
          </template>
        </van-field>
        <van-field v-model="form.logistics_name" is-link readonly name="logistics_name" label="物流公司" placeholder="请选择物流公司" required :rules="[{ required: true, message: '请选择物流公司' }]" @click="()=>{if(isDisabled){return} logisticsShow = true}" v-if='form.delivery_type == 1' />
        <van-field v-model="form.logistics_code" name="logistics_code" :disabled='isDisabled' label="物流单号" required :rules="[{ required: true, message: '请输入物流单号' }]" placeholder="请输入物流单号" v-if='form.delivery_type == 1' />
        <van-field name="radio" label="扫码类型">
          <template #input>
            <van-radio-group v-model="form.type" direction="horizontal" checked-color='#5B9DFF' @change='selectRadio'>
              <van-radio :name="1">扫码出货</van-radio>
              <van-radio :name="2">扫码回退</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.code" name="code" label="二维码" ref="codeEle" autocomplete="off"  required placeholder="请输入二维码" >
          <template #button>
              <!-- native-type="submit" -->
            <van-button style="width:66px" block color='#5B9DFF' size="small" type="primary" native-type="submit" @click="Shipment">{{form.type == 1 ? '出货':'回退'}}</van-button>
          </template>
        </van-field>
        <div class="s_flex_bian_c s_wrap fz-14 p-15" v-if='showResult'>
          <p class="width50">扫码数量：{{scanCodeNum}}</p>
          <p class="width50">商品数量：{{successGoodsNum}}</p>
          <p class="width100">
            <span v-if='form.type == 1'>扫码结果：{{resultCode == 0 ? '出库成功！':'出库失败！'}}</span>
            <span v-else>扫码结果：{{resultCode == 0 ? '回退成功！':'回退失败！'}}</span>
          </p>
          <p class="width100 m-t-15" v-if='resultCode != 0'>{{resultMsg}}</p>
        </div>
      </van-form>
         <!-- <div class="tw-fixed tw-left-0 tw-bottom-0 s_flex_center width100 p-15" v-if="form.out_warehousing_sn">
          <van-button block round color="linear-gradient(to right, #53A1FF, #68D0FF)"  @click="onSubmit">确定出库</van-button>
        </div> -->
    </div>
    <div v-if='active == 2'>
      <div class="p-10" v-if='goodsList.length'>
        <div class="s_bf p-10 m-b-10 tw-rounded-sm" v-for="(item,index) in goodsList" :key='index'>
          <div class="s_flex_bian_c">
            <img class="width-80 height-80 tw-rounded-sm" :src="info.oss_domain+item.product_image" alt="">
            <div class="width72">
              <p class="fz-15 s_omit2">{{item.product_name}}</p>
              <p class="tw-text-gray-400 m-t-5">规格：{{item.product_sku_name || '单规格'}}</p>
              <p class="tw-text-gray-400">编号：{{item.product_sn}}</p>
            </div>
          </div>
          <van-divider :style="{ margin: '10px 0' }"/>
          <p class="fz-14">商品数量：{{item.out_warehousing_real_num}}</p>
        </div>
        <p class="tw-text-center tw-text-gray-400 fz-14 p-t-10 p-b-10">我也是有底线的~</p>
      </div>
      <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无商品" />
    </div>
    <div  v-if='active == 3' class="m-t-10 content tw-flex tw-flex-col tw-flex-1">
      <van-search v-model="search.keyword" show-action placeholder="请输入物流码进行搜索" @search="onRefresh" >
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onRefresh">搜索</div>
        </template>
      </van-search>
      <div class="tw-flex-1 p-15">
        <div class="s_flex_bian_c s_bf5 height-40 tw-border tw-border-gray-200 tw-border-solid tw-bg-gray-300 tw-bg-opacity-30" v-if='list.length'>
          <p class="width33 s_flex_center">物流码</p>
          <p class="width33 s_flex_center">码类型</p>
          <p class="width33 s_flex_center">商品数量</p>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="search.out_warehousing_sn">
          <van-list v-model:loading="loading" :finished="finished" :finished-text="list.length?'没有更多了':''" @load="onLoad">
            <div class="s_flex_bian_c height-40 tw-border tw-border-gray-200 tw-border-solid tw-border-t-0 s_bf" v-for="(item,index) in list" :key='index'>
              <p class="tw-text-center width33">{{item.code_sn}}</p>
              <p class="tw-text-center width33">{{item.code_type_text}}</p>
              <p class="tw-text-center width33">{{item.code_num}}</p>
            </div>
          </van-list>
        </van-pull-refresh>
      <van-empty v-if='!list.length' :image="require('@/assets/image/empty.png')" description="暂无物流码" />
      </div>
    </div>

    <!-- 选择订单 -->
    <van-popup v-model:show="orderShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectOrder orderTpye='return' @select='orderConfirm'></selectOrder>
    </van-popup>

    <!-- 选择收货人 -->
    <van-popup v-model:show="storeShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectStore @select='storeConfirm'></selectStore>
    </van-popup>

     <!-- 选择物流公司 -->
    <van-popup v-model:show="logisticsShow" position="bottom" round safe-area-inset-bottom :style="{ maxHeight: '80%'}">
      <selectLogistics @cancel='logisticsShow = false' @confirm='logisticsConfirm' ></selectLogistics>
    </van-popup>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { iscode } from '@/utils/app.js';
  import { Toast,Dialog } from 'vant';
  import { checkIncomplete,clearIncomplete,setOutWarehouse,qrScan,updateOrder,productInfo,productCode,outWarehouseOrder} from '@/api/outWarehouse.js';
  import {useList} from '@/hooks/useListHandle'
  import {useWare} from '@/hooks/function'
import { useWatchScan } from "@/hooks/scanWatch.js";
  import store from '@/store'
  let defForm = {
    out_warehousing_sn: '',
    out_warehousing_type:'',
    out_warehousing_to_id:'',
    logistics_code:'',
    logistics_name:'',
    code:'',
    out_warehousing_to_name:'',
    delivery_type:2,
    type:1,
  };
  export default {
    setup() {
      const form = ref({ ...defForm });
      const state = reactive({
        active: 1,
        is_out_scan:true,//是否校验二维码
        goodsList:[],
        warehouseShow:false, //是否显示仓库选择
        orderShow:false, //是否显示订单选择
        isDisabled:false, //是否禁用某些选项
        showResult:false, // 是否显示扫码结果
        scanCodeNum:0, //扫码数量
        successGoodsNum:0, //商品数量
        resultCode:'', //扫码结果code
        resultMsg:'', // 扫码结果提示语
        info:store.state.user.info,
        typeCheck:false, //扫码类型切换，暂时隐藏入库成功/回退成功
        storeShow:false,//是否显示收货人选择
        showType:false, //物流配送开关
        logisticsShow:false,
        keyword:'',
      })
      const { onRefresh, search,finished, refreshing, onLoad, list, loading,resetLoading } = useList(productCode,{keyword: "",limit: 10,page: 1,out_warehousing_sn:''});
      const audios=ref('')
      const codeEle=ref('');
      const loadingShipment=ref(false)
      // 方法
      const methods={
         // 检测上一次是否完成
         checkOutStatus:async function(){
            const result=await checkIncomplete().then(res=>res.data).catch(error=>error)
            if(iscode(result)){
              if(result.data.out_warehousing_sn){
                Dialog.confirm({
                  title: '温馨提示',
                  cancelButtonText:'删除',
                  message: `您上次有未完成单号${result.data.out_warehousing_sn},是否继续？`,
                }).then(() => {
                    form.value.out_warehousing_sn = result.data.out_warehousing_sn;
                    methods.getInfo(result.data.out_warehousing_sn);
                  }).catch(() => {
                    methods.deleteOrder(result.data.out_warehousing_sn)
                  });
              }
            }else{
               Toast.fail(result.msg);
            }
          },

          //扫码 / 回退
          Shipment:async function(){
         
            codeEle.value.blur()
            if(loadingShipment.value)return
            if(!form.value.code){
                Toast.fail('请输入二维码');
                return
            }
            if(!form.value.out_warehousing_to_name){
                Toast.fail('请选择收货人');
              return
            }
            loadingShipment.value=true
            Toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration:0
            });
             const result = await qrScan({...form.value,sn:form.value.out_warehousing_sn}).then(res=>res.data);
            loadingShipment.value=false
             form.value.code=''
             Toast.clear();
             if (iscode(result)) {
                audios.value.pass()
                form.value.out_warehousing_sn = result.data.sn;
                state.resultMsg = result.msg;
                state.resultCode = result.code;
                state.scanCodeNum+=1;
                state.isDisabled = true;
                if(form.value.type == 1){
                   state.successGoodsNum = state.successGoodsNum+result.data.num
                  }else{
                    state.successGoodsNum = state.successGoodsNum-result.data.num
                }
                if(!state.showResult){
                  state.showResult = true;
                }
             }else{
                audios.value.error()
                Toast.fail(result.msg);
             }
             codeEle.value.focus()
          },
          //确定出货
          onSubmit: async function(values){
            Toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration:0
            });
            const result = await setOutWarehouse({ ...form.value}).then(res => res.data).catch(error => error)
            Toast.clear();
            if (iscode(result)) {
              // $router.push('/outWarehouse/byFastCode?sn='+result.data.out_warehousing_sn)
              Toast.success('出货成功');
              audios.value.pass()

              setTimeout(()=>{
                location.reload()
              },500)
		        }else{
              Toast.fail(result.msg);
              audios.value.error()
		        }
         },
         // 收货人选择
         storeConfirm(e){
           form.value.out_warehousing_to_id = e.id;
           form.value.out_warehousing_to_name = e.name;
           form.value.out_warehousing_type = e.out_warehousing_type;
           state.storeShow = false;
           if(form.value.out_warehousing_sn){ //有订单号才更新
            // methods.postUpdate()
           }
         },
         //更新订单(智能防窜2.0优化舍弃)
        //  postUpdate:async function(){
        //     Toast.loading({
        //       message: '加载中...',
        //       forbidClick: true,
        //       duration:0
        //     });
        //    const result = await updateOrder({...form.value}).then(res=>res.data);
        //    Toast.clear();
        //    if (!iscode(result)) return Toast.fail(result.msg);
        //  },
         //扫码类型
        selectRadio(){
           state.showResult = false;
           if(form.value.out_warehousing_sn){ //有订单号才更新
            //  methods.postUpdate()
           }
         },
          // 物流选择
        logisticsConfirm(e){
           form.value.logistics_name = e.name
           state.logisticsShow = false;
           if(form.value.out_warehousing_sn){ //有订单号才更新
            //  methods.postUpdate()
           }
         },
         //获取商品
         getProductInfo: async function(){
           if(!form.value.out_warehousing_sn)return
           Toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration:0
            });
             const result = await productInfo({out_warehousing_sn:form.value.out_warehousing_sn}).then(res=>res.data);
             Toast.clear();
             if (iscode(result)){
               state.goodsList = result.data;
             }else{
                Toast.fail(result.msg);
             }
         },
         //出货方式
        onSelect(e){
          form.value.delivery_type = state.showType?1:2;
           if(form.value.out_warehousing_sn){ //有订单号才更新
            //  methods.postUpdate()
           }
         },
         //取消发货单
         deleteOrder:async function(out_warehousing_sn){
           Toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration:0
            });
            const result = await clearIncomplete({out_warehousing_sn:out_warehousing_sn}).then(res=>res.data);
            Toast.clear();
             if (iscode(result)){
               Toast.success(result.msg);
             }else{
               Toast.fail(result.msg);
             }
         },
         //出货单详情
         getInfo:async function(out_warehousing_sn){
           Toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration:0
            });
            const result = await outWarehouseOrder({sn:out_warehousing_sn}).then(res=>res.data);
              Toast.clear();
             if (iscode(result)){
                form.value = result.data;
                state.showType = form.value.delivery_type==1 ? true : false;
                form.value.type = 1;
                form.value.out_warehousing_to_name = result.data.to;
                state.isDisabled = true;
             }else{
               Toast.fail(result.msg);
             }
         }
      }



      onMounted(async() => {

          // form.value.delivery_name = form.value.delivery_type == 2 ? '当面交易':'快递发货';
          // methods.checkOutStatus()
    //     getSn();
    //                  // 设置默认仓库
    //    let ware= await useWare().getWareList()
    //     form.value.refund_warehouse_id = ware.data[0].id;
    //     form.value.position_id = 0;
    //     form.value.warehouse_name = ware.data[0].name
      })
         useWatchScan().openWatch((status) => {
            if (!status && !form.value.code) {
              codeEle.value.focus();
            }
      });
      return {
        ...toRefs(state),
        form,
        codeEle,
        // onSubmit,
        // warehouseConfirm,
        // orderConfirm,
        // onRefresh,
        finished,
        loading,
        refreshing,
        list,
        search,
        onLoad,
        resetLoading,
        audios,
        onRefresh,
        ...methods
      };
    }
  };
</script>
